import React, { FunctionComponent } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useCMS, useForm, usePlugin } from "tinacms";

export const CommercializationCTA: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("call-to-action");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "4.ntbc-call-to-action",
    label: "7. Call To Action",
    fields: [
      {
        name: "body",
        label: "Call to Action",
        component: "markdown",
      },
      {
        name: "buttonText",
        label: "Button Text",
        component: "text",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="bg-green-600 py-8 px-8">
      <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-white">
          {editableData.body}
        </h1>
        <a
          href="https://applications.ntbc.co.zm"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-shrink-0 text-green-600 bg-green-100 border-0 py-2 px-8 focus:outline-none hover:bg-green-400 rounded text-lg mt-10 sm:mt-0"
        >
          {editableData.buttonText}
        </a>
      </div>
    </div>
  );
};
