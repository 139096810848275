import React, { FunctionComponent } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useCMS, useForm, usePlugin } from "tinacms";
import { OtherProgram } from "./ProgrammeComponent";

export const OtherProgrammes: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("other-programmes");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "6.ntbc-other-programmes",
    label: "5. Other Programmes Section",
    fields: [
      {
        name: "firstImage",
        label: "First Programme Image",
        component: "image",
      },
      {
        name: "firstProgrammeCategory",
        label: "First Programme Category",
        component: "text",
      },
      {
        name: "firstProgrammeTitle",
        label: "First Programme Title",
        component: "text",
      },
      {
        name: "firstProgrammeSummary",
        label: "First Programme Summary",
        component: "markdown",
      },
      {
        name: "firstProgrammeLink",
        label: "First Programme Link",
        component: "text",
      },
      {
        name: "secondImage",
        label: "Second Programme Image",
        component: "image",
      },
      {
        name: "secondProgrammeTitle",
        label: "Second Programme Title",
        component: "text",
      },
      {
        name: "secondProgrammeSummary",
        label: "Second Programme Summary",
        component: "markdown",
      },
      {
        name: "secondProgrammeLink",
        label: "Second Programme Link",
        component: "text",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);
  return (
    <div className="px-4 md:px-8 py-8 bg-green-100">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="pl-4 md:pl-0 text-3xl sm:text-5xl font-extrabold">
          Other <span style={{ color: "#ed8433" }}>Programmes</span>
        </h3>
        <div className="flex flex-wrap">
          <OtherProgram
            category={editableData.firstProgrammeCategory}
            title={editableData.firstProgrammeTitle}
            description={editableData.firstProgrammeSummary}
            imagePath="https://res.cloudinary.com/grethl/image/upload/v1605195657/124979029_3572943962766704_8100591090512291637_o_g5tuz5.jpg"
            url={editableData.firstProgrammeLink}
          />
          <OtherProgram
            category={editableData.secondProgrammeCategory}
            title={editableData.secondProgrammeTitle}
            description={editableData.secondProgrammeSummary}
            url={editableData.secondProgrammeLink}
            imagePath="https://res.cloudinary.com/ntbc-conduit/image/upload/v1597067504/other-programmes/LUO-Lundazi-Green-solar-panels-power_ktgxsj.jpg"
          />
        </div>
        <div className="flex">
          <div className="flex-1"></div>
          <div className="hidden lg:block flex-1 pl-8"></div>
        </div>
      </div>
    </div>
  );
};
