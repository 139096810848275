import React, { FunctionComponent } from "react";
import Lottie from "lottie-react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NTBCNavigation } from "./NTBCNavigation";
import { useCMS, useForm, usePlugin } from "tinacms";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import styled from "styled-components";

import "swiper/swiper.scss";
import gears2 from "../../assets/lottie/19748-gear-animation.json";

SwiperCore.use([Autoplay]);

const HeroImage = styled.div<{ img: string }>`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.img});
  img {
    backdrop-filter: blur(10px);
  }
`;

export const NTBCHero: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("hero-section");

  const data = useFirestoreDocData<any>(programmesRef);

  const heroImagesRef = useFirestore().collection("hero-images");
  const heroImages = useFirestoreCollectionData<any>(heroImagesRef);

  // const images = [
  //   { name: "first", path: "/static/dawa_health.jpg" },
  //   { name: "second", path: "/static/natures_nectar.jpg" },
  //   { name: "third", path: "/static/undp_mou.jpg" },
  // ];

  const formConfig = {
    id: "9-zntbc-hero",
    label: "1. Hero Section",
    fields: [
      {
        name: "subtitle",
        label: "Subtitle",
        component: "markdown",
      },
      {
        name: "firstImage",
        label: "First Hero Image",
        component: "image",
      },
      {
        name: "secondImage",
        label: "Second Hero Image",
        component: "image",
      },
      {
        name: "thirdImage",
        label: "Third Hero Image",
        component: "image",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto bg-opacity-0">
        <div className="relative z-10 pb-8 bg-white bg-opacity-0 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <NTBCNavigation />
          </div>

          <main className="text-center mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-10">
            <div className="sm:text-center lg:text-left">
              <p className="pb-6 text-gray-500">
                National Technology and Business Centre
              </p>
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl ">
                Turning &nbsp;
                <span className="text-green-600">ideas</span>
                {" and "}
                <br className="xl:hidden" />
                <span style={{ color: "#ed8433" }}>innovations</span> into
                wealth.
              </h2>
              <div className="mt-3 text-gray-500 sm:mt-5  sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0">
                {editableData.subtitle}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="image-container lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Swiper
          slidesPerView={1}
          effect="fade"
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          lazy
        >
          {heroImages?.map((x) => {
            return (
              <SwiperSlide key={x.path}>
                <HeroImage img={x.path}>
                  <img
                    src={x.path}
                    alt={x.name}
                    className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
                  />
                </HeroImage>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="absolute top-0 inset-x-0 sm:left-8 sm:w-1/2 opacity-25">
        <Lottie animationData={gears2} />
      </div>
    </div>
  );
};
