import React, { FunctionComponent, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LogoImage from "../../assets/images/ntbc_logo.png";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/twitter-icon.svg";
import { useCMS } from "tinacms";
import { useAuth } from "reactfire";
import { SignInModal } from "./SignInModal";
// import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube-icon.svg";

const Container = tw.div`relative bg-green-800 text-gray-100 -mx-8 -mb-8 px-8 pb-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8 md:pl-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-green-800 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div`text-center`;
const CompanyInfo = tw.div``;

const Divider = tw.div`my-8 border-b-2 border-green-700`;

export const Footer: FunctionComponent = () => {
  const auth = useAuth();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const cms = useCMS();

  const enterEditMode = () => {
    setModalVisible(true);
  };

  const exitEditMode = () => {
    cms.disable();
  };

  const loginToAdmin = async (email: string, pass: string) => {
    if (!email || !pass) return;
    auth
      .signInWithEmailAndPassword(email, pass)
      .then((res) => {
        console.log(res.user);
        setModalVisible(false);
        cms.enable();
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      });
  };

  return (
    <footer className="overflow-hidden">
      {isModalVisible && (
        <SignInModal
          onCancelClick={() => setModalVisible(false)}
          onLoginClick={loginToAdmin}
          error={error}
        />
      )}
      <Container>
        <Content>
          <FiveColumns>
            <CompanyColumn>
              <LogoContainer>
                <LogoImg src={LogoImage} />
                <LogoText>NTBC</LogoText>
              </LogoContainer>
              <CompanyAddress>
                8th Floor, Nasser Road, New Government Complex, Lusaka Zambia.
              </CompanyAddress>
              <SocialLinksContainer>
                <SocialLink href="https://facebook.com/NtbcZambia">
                  <FacebookIcon />
                </SocialLink>
                <SocialLink href="https://twitter.com/ntbc_zambia">
                  <TwitterIcon />
                </SocialLink>
              </SocialLinksContainer>
            </CompanyColumn>
            <Column>
              <ColumnHeading>Quick Links</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link href="https://blog.ntbc.co.zm">Blog</Link>
                </LinkListItem>
                <LinkListItem>
                  <Link href="https://community.ntbc.co.zm">Community</Link>
                </LinkListItem>
                <LinkListItem>
                  <Link onClick={enterEditMode} onDoubleClick={exitEditMode}>
                    Admin Mode
                  </Link>
                </LinkListItem>
              </LinkList>
            </Column>
            <Column>
              <ColumnHeading>Innovation Portal</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link href="https://ntbc.co.zm/apply">Submissions</Link>
                </LinkListItem>
                <LinkListItem>
                  <Link href="https://ntbc.co.zm/apply">
                    Mobile Application
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link href="https://platform.ntbc.co.zm">
                    Platform
                  </Link>
                </LinkListItem>
              </LinkList>
            </Column>
            <Column>
              <ColumnHeading>Legal</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link href="https://ntbc.co.zm/privacy-policy">
                    Privacy Policy
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link href="https://ntbc.co.zm/privacy-policy">
                    Terms of Service
                  </Link>
                </LinkListItem>
              </LinkList>
            </Column>
            <Column>
              <ColumnHeading>Contact</ColumnHeading>
              <LinkList>
                <LinkListItem>+260 (211) 222-335</LinkListItem>
                <LinkListItem>+260 (211) 222-409</LinkListItem>
                <LinkListItem>
                  <Link href="mailto:info@ntbc.co.zm">info@ntbc.co.zm</Link>
                </LinkListItem>
              </LinkList>
            </Column>
          </FiveColumns>
          <Divider />
          <CopyrightAndCompanyInfoRow>
            <CopyrightNotice>
              &copy; Copyright 2020, National Technology Business Centre.
            </CopyrightNotice>
            <CompanyInfo>
              Turning Ideas and Innovations Into Wealth.
            </CompanyInfo>
          </CopyrightAndCompanyInfoRow>
        </Content>
      </Container>
    </footer>
  );
};
