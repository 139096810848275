import React, { Fragment, FunctionComponent } from "react";
import { NTBCHero } from "./sections/hero";
import { AboutContent } from "./sections/about-us";
import { ServicesSummary } from "./sections/our-services";
import { Programmes } from "./sections/our-programmes";
import { OtherProgrammes } from "./sections/other-programmes/OtherProgrammes";
import { Library } from "./sections/resource-center";
import { CommercializationCTA } from "./sections/call-to-actions";
import { Partnerships } from "./sections/our-partners";
import { ContactUs } from "./sections/contact-us";
import { Footer } from "./sections/footer";
import { EventsOpportunities } from "./sections/events-opportunities/EventsOpportunities";

export const LandingPage: FunctionComponent = () => {
  return (
    <Fragment>
      <NTBCHero />
      <AboutContent />
      <ServicesSummary />
      <EventsOpportunities />
      <Programmes />
      <OtherProgrammes />
      <Library />
      <CommercializationCTA />
      <Partnerships />
      <ContactUs />
      <Footer />
    </Fragment>
  );
};
