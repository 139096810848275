import React, { FunctionComponent } from "react";
import saislogo from "../../assets/images/sais-logo-2.jpg";
import idinlogo from "../../assets/images/idin-logo-1.png";
import { useCMS, useForm, usePlugin } from "tinacms";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const Programmes: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("our-programmes");

  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "7.ntbc-our-programmes",
    label: "4. Our Programmes Section",
    fields: [
      {
        name: "firstLogo",
        label: "First Programme Logo",
        component: "image",
      },
      {
        name: "firstProgrammeBody",
        label: "First Programme Body",
        component: "markdown",
      },
      {
        name: "secondLogo",
        label: "Second Programme Logo",
        component: "image",
      },
      {
        name: "secondProgrammeBody",
        label: "Second Programme Body",
        component: "markdown",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="px-8 py-8">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="text-3xl sm:text-5xl font-extrabold">
          Our <span className="text-green-600">Programmes</span>
        </h3>
        <div className="flex">
          <div className="flex-1">
            <img src={saislogo} alt="SAIS logo" className="h-16 lg:h-20 my-4" />
            <p className="">{editableData.firstProgrammeBody}</p>
            <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
              <li className="py-1">
                Improving capacity of innovation institutions and intermediaries
                in the region for supporting private sector innovation and
                enterprise development.
              </li>
              <li className="py-1">
                Improving capacity of enterprises to innovate and enter new
                markets.
              </li>
              <li className="py-1">
                Improving the enabling environment for the inclusive innovation
                in the SADC region.
              </li>
            </ul>
          </div>
          <div className="hidden lg:block flex-1 pl-8">
            <img src={idinlogo} alt="IDIN logo" className="h-16 lg:h-20 my-4" />
            <p className="text-gray-900">{editableData.secondProgrammeBody}</p>
            <p className="mt-4 text-gray-900">Products under IDIN include:</p>
            <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
              <li className="py-1">Create Capacity Building.</li>
              <li className="py-1">PICO Grants</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
