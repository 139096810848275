import React, { FunctionComponent } from "react";

interface IOtherProgramProps {
  category: string;
  title: string;
  description: string;
  imagePath: string;
  url: string;
}

export const OtherProgram: FunctionComponent<IOtherProgramProps> = (props) => {
  return (
    <div className="md:w-1/3 md:mr-4 mt-4">
      <div className="h-full rounded overflow-hidden shadow">
        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={props.imagePath}
          alt="blog"
        />
        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
            {props.category}
          </h2>
          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
            {props.title}
          </h1>
          <p className="leading-relaxed mb-3">{props.description}</p>
          <div className="flex items-center flex-wrap ">
            <a
              className="text-green-600 inline-flex items-center md:mb-2 lg:mb-0"
              href={props.url}
            >
              Read More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
