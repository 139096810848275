import React, { Fragment, FunctionComponent } from "react";
import Lottie from "lottie-react";

import ntbc2 from "../../assets/images/ntbc-1.jpg";
import economyLottie from "../../assets/lottie/economy4.json";
import { useCMS, useForm, usePlugin } from "tinacms";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const AboutContent: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore().collection("home-page").doc("about-us");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "9-ntbc-about-us",
    label: "2. About Us Section",
    fields: [
      {
        name: "whoWeAre",
        label: "Who we are",
        component: "markdown",
      },
      {
        name: "ourVision",
        label: "Our Vision",
        component: "markdown",
      },
      {
        name: "ourMission",
        label: "Our Mission",
        component: "markdown",
      },
      {
        name: "aboutUsImage",
        label: "About Us Image",
        component: "image",
      },
    ],
    initialValues: data,

    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);

  usePlugin(form);
  return (
    <Fragment>
      <div className="relative bg-green-100 pb-8 md:pb-32 lg:pb-0">
        <div className="max-w-screen-xl mx-auto">
          <div className="lg:flex">
            <div className="hidden lg:block lg:flex-1 px-8 py-8">
              <h3 className="text-3xl sm:text-5xl font-extrabold">
                Who we
                <span className="text-green-600"> are</span>
              </h3>
              <p className="text-gray-900">{editableData.whoWeAre}</p>
              <img
                src={ntbc2}
                alt="ntbc"
                className="h-72 w-full object-cover rounded mt-4 shadow-lg"
              />
            </div>
            <div className="bg-green-100 px-8 py-8 lg:flex-1">
              <div>
                <h3 className="text-3xl sm:text-5xl font-extrabold">
                  Our <span className="text-green-600">Vision</span>
                </h3>
                <p className="text-gray-900">{editableData.ourVision}</p>
                <h3 className="text-3xl sm:text-5xl font-extrabold mt-4">
                  Our <span style={{ color: "#ed8433" }}>Mission</span>
                </h3>
                <p className="text-gray-900">{editableData.ourMission}</p>
                <p className="hidden lg:block mt-8 text-gray-900">
                  Our key role is to assist in the transformation of new ideas,
                  innovations, and technologies into products and new businesses
                  that are financially viable.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-green-100 absolute bottom-0 right-0 w-full lg:w-1/2 transform md:translate-y-2">
          <Lottie animationData={economyLottie} />
        </div>
      </div>
    </Fragment>
  );
};
